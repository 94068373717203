<template>
  <b-container fluid>
    <b-row class="mb-3">
      <b-col
        cols="6"
        lg="4"
        class="text-md-left text-center"
      >
        <span class="h4 text--white">Companies</span>
      </b-col>
      <b-col
        cols="6"
        lg="4"
        offset-lg="4"
        class="text-right mt-2 mt-lg-0"
      >
        <AddEditCompany @refresh="init()"></AddEditCompany>
      </b-col>
    </b-row>
    <b-overlay :show="loading">
      <b-card class="card-border text--black q-table-container" style="overflow-x: auto">
        <div class="float-left mb-2">
          Search <b-form-input v-model="text" debounce="500" class="d-inline w-auto"></b-form-input>
        </div>
        <div class="float-left mb-2 ml-3">
          <b-checkbox v-model="filters.active" :value="null" :unchecked-value="1">Show in-active</b-checkbox>
        </div>
        <b-pagination
          class="float-right"
          v-if="totalItems > filters.max"
          v-model="filters.page"
          :total-rows="totalItems"
          :per-page="filters.max"
          aria-controls="documentsTable"
          @change="changePage"
        ></b-pagination>
        <b-table
          style="min-width: 1080px;"
          @sort-changed="sortingChanged"
          :items="companies"
          no-local-sorting
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="desc"
          striped
        >
          <template #cell(name)="data">
            <router-link :to="'/admin/company/' + data.item.id" class="link u">
              {{ data.item.name }}
            </router-link>
          </template>
          <template #cell(organization)="data">
            <AddEditOrganization
              @refresh="init()"
              :name="data.item.organization"
              :id="+data.item.organization_id"
              :edit="true"
            >
              <template>
                <span class="link u">
                  {{ data.item.organization }}
                </span>
              </template>
            </AddEditOrganization>
          </template>
          <template #cell(actions)="data">
            <div class="text-right">
              <AddEditCompany @refresh="init()" :compId="+data.item.id" edit>
                <template>
                  <b-button variant="warning"><b-icon icon="pencil"></b-icon></b-button>
                </template>
              </AddEditCompany>
              <AddUser @refresh="init()" :orgId="+data.item.organization_id" :companyId="+data.item.id" class="ml-2">
                <template>
                  <b-button variant="success"><b-icon icon="person-plus-fill"></b-icon></b-button>
                </template>
              </AddUser>
              <div class="ml-2 d-inline">
                <b-button @click="$router.push('/documents/' + data.item.id)">
                  <b-icon icon="file-text"></b-icon>
                </b-button>
              </div>
            </div>
          </template>
        </b-table>
        <b-pagination
          class="float-right"
          v-if="totalItems > filters.max"
          v-model="filters.page"
          :total-rows="totalItems"
          :per-page="filters.max"
          aria-controls="documentsTable"
          @change="changePage"
        ></b-pagination>
        <div class="pl-1 text-left" v-if="totalItems > 10">
          Per page:
          <span
            @click="changeMax(10)"
            :class="filters.max == 10 ? 'active' : ''"
            class="ml-2 link"
          >10</span>
          <span
            href="javasript:void(0)"
            @click="changeMax(25)"
            :class="filters.max == 25 ? 'active' : ''"
            class="ml-2 link"
          >25</span>
          <span
            href="javasript:void(0)"
            @click="changeMax(50)"
            :class="filters.max == 50 ? 'active' : ''"
            class="ml-2 link"
          >50</span>
          <span
            href="javasript:void(0)"
            @click="changeMax(100)"
            :class="filters.max == 100 ? 'active' : ''"
            class="ml-2 link"
          >100</span>
        </div>
      </b-card>
    </b-overlay>

  </b-container>
</template>
<script>
import CompanyService from '@/services/CompanyService'
import AdminService from '@/services/AdminService'
import AddEditOrganization from '@/components/admin/OrganizationModal'
import AddEditCompany from '@/components/admin/CompanyModal'
import AddUser from '@/components/admin/OrgUserModal'

export default {
  data () {
    return {
      filters: {
        max: 25,
        page: 1,
        order: [{
          column: 'c.updated',
          direction: 'desc'
        }],
        text: '',
        bookkeeper: null,
        active: 1
      },

      text: '',

      totalItems: 0,

      sortBy: 'updated',
      desc: true,

      fields: [
        { key: 'name', label: 'Company', sortable: true },
        { key: 'organization', sortable: true },
        { key: 'companytype', label: 'Company Type', sortable: true },
        { key: 'updated', sortable: true, sortDirection: 'desc' },
        { key: 'actions', label: '', sortable: false }
      ],

      companies: [],
      bookkeepers: [],

      loading: false
    }
  },

  components: {
    AddEditOrganization,
    AddEditCompany,
    AddUser
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      this.search()
      AdminService.getBookkeepers().then(
        (response) => {
          this.bookkeepers = response.data.info
        }
      )
    },

    search () {
      this.loading = true
      this.companies = []
      CompanyService.search({ filters: this.filters }).then(
        (response) => {
          this.companies = response.data.info.results
          this.totalItems = response.data.info.totalResults
        }
      ).finally(() => { this.loading = false })
    },

    resetFilters () {
      this.filters = {
        max: 25,
        page: 1,
        order: [{
          column: 'c.updated',
          direction: 'desc'
        }],
        text: '',
        bookkeeper: null,
        active: 1
      }

      this.text = ''

      this.sortBy = 'updated'
      this.desc = true
    },

    changePage (page) {
      this.filters.page = page
    },

    changeMax (max) {
      this.filters.max = max
      this.filters.page = 1
    },

    sortingChanged (event) {
      this.filters.page = 1
      switch (event.sortBy) {
        case 'name':
          this.filters.order[0].column = 'c.name'
          break
        case 'companytype':
          this.filters.order[0].column = 'ct.id'
          break
        case 'updated':
          this.filters.order[0].column = 'c.updated'
          break
        default:
          this.filters.order[0].column = event.sortBy
          break
      }

      if (event.sortDesc) {
        this.filters.order[0].direction = 'desc'
      } else {
        this.filters.order[0].direction = 'asc'
      }
    }
  },

  watch: {
    filters: {
      handler: async function (n, o) {
        if (n) {
          if (n.text !== o.text) {
            this.filters.page = 1
          }
          this.search()
        }
      },
      deep: true
    },

    text (n, o) {
      if (n !== o) {
        this.filters.page = 1
        this.filters.text = n
      }
    }
  }
}
</script>
