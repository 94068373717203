<template>
  <div class="d-inline">
    <div @click="showModal = true" class="ma-0 pa-0 d-inline">
      <slot>
        <b-button
          variant="success"
          block
        >
          <b-icon :icon="edit ? 'pencil' : 'plus'"></b-icon>
          {{ edit ? 'Edit' : 'Add' }} Organization
        </b-button>
      </slot>
    </div>

    <b-modal
      v-model="showModal"
      id="updateorganization"
      :title="(edit ? 'Edit' : 'Add') + ' Organization'"
      @hidden="reset()"
      @shown="reset()"
      @ok="createOrg"
      ok-variant="primary"
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-form-group label="Name" label-align="left">
        <b-input v-model="organization.name"></b-input>
      </b-form-group>

      <template #modal-ok>
        <b-icon icon="check"></b-icon> Save
      </template>
      <template #modal-cancel>
        <b-icon icon="x"></b-icon> Cancel
      </template>
    </b-modal>
  </div>
</template>
<script>
import CompanyService from '@/services/CompanyService'

export default {

  props: {
    id: {
      type: Number,
      default: 0
    },
    name: {
      type: String,
      default: null
    },

    edit: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      organization: {
        name: null
      },
      showModal: false
    }
  },

  methods: {
    reset () {
      this.organization.name = this.name
    },

    createOrg (bvModalEvt) {
      bvModalEvt.preventDefault()

      const data = {
        name: this.organization.name,
        id: this.id
      }

      CompanyService.updateOrganization(data).then(
        (response) => {
          this.$aimNotify.notify(response)

          this.$nextTick(() => {
            this.showModal = false
            this.$emit('refresh')
          })
        }
      )
    }

  }

}
</script>
