<template>
  <div class="d-inline">
    <div
      @click="showModal = true"
      class="ma-0 pa-0 d-inline"
    >
      <slot>
        <b-button
          v-if="edit"
          variant="success"
          block
        >
          <b-icon icon="pencil"></b-icon>
          Edit User
        </b-button>
        <b-button
          variant="success"
          v-else
          block
        >
          <b-icon icon="plus"></b-icon>
          Add User
        </b-button>
      </slot>
    </div>

    <b-modal
      v-model="showModal"
      id="addorguser"
      title="Add User"
      @hidden="reset()"
      @shown="init()"
      @ok="createUser"
      ok-variant="primary"
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-form-group
        label="First Name"
        label-align="left"
      >
        <b-input v-model="firstname"></b-input>
      </b-form-group>
      <b-form-group
        label="Last Name"
        label-align="left"
      >
        <b-input v-model="lastname"></b-input>
      </b-form-group>
      <b-form-group
        label="Email"
        label-align="left"
      >
        <b-input v-model="email"></b-input>
      </b-form-group>

      <template #modal-ok>
        <b-icon icon="check"></b-icon> Save
      </template>
      <template #modal-cancel>
        <b-icon icon="x"></b-icon> Cancel
      </template>
    </b-modal>
  </div>
</template>
<script>
import CompanyService from '@/services/CompanyService'
import AdminService from '@/services/AdminService'

export default {
  props: {
    orgId: {
      type: Number,
      default: 0
    },
    userId: {
      type: Number,
      default: 0
    },
    edit: {
      type: Boolean,
      default: false
    },
    companyId: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      firstname: null,
      lastname: null,
      email: null,
      showModal: false
    }
  },

  methods: {
    init () {
      if (this.userId > 0) {
        const filters = {
          id: this.userId,
          organization_id: this.orgId
        }
        AdminService.getUsers(filters).then(
          (response) => {
            console.log(response)
            const user = response.data.info.users[0]
            this.firstname = user.firstname
            this.lastname = user.lastname
            this.email = user.email
          }
        )
      } else {
        this.reset()
      }
    },

    createUser (bvModalEvt) {
      bvModalEvt.preventDefault()
      const data = {
        id: this.userId,
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        organization_id: this.orgId,
        company_id: this.companyId
      }
      CompanyService.updateUser(data).then(
        (response) => {
          this.$aimNotify.notify(response)

          this.$nextTick(() => {
            this.showModal = false
            this.$emit('refresh')
          })
        }
      ).catch((err) => {
        this.$aimNotify.error(err.response)
        console.dir(err)
      })
    },

    reset () {
      this.firstname = null
      this.lastname = null
      this.email = null
    }
  }

}
</script>
